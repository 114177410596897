<template>
  <section id="pengajuan-daftar-surat">
    <b-row>
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="userData.instansi_id" />
        <profile-side-menu />
      </b-col>

      <!-- post -->
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-card
          header="Tambah Dokumen Efisiensi Anggaran"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <b-alert
            variant="info"
            show
          >
            <div class="alert-body">
              <span>
                Contoh Format Dokumen Matrix Efisiensi dapat diunduh pada
                <b-link
                  class="btn btn-sm btn-success ml-50 mb-25"
                  target="_blank"
                  href="/panduan/template-matrix-efisiensi.xlsx"
                >link berikut</b-link>
              </span>
            </div>
          </b-alert>

          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div
                v-if="alertDetail.variant === 'danger' && alertDetail.errors !== ''"
                class="alert-body"
              >
                <ul v-if="typeof alertDetail.errors === 'object'">
                  <li
                    v-for="(item, index) in alertDetail.errors"
                    :key="index"
                  >
                    {{ index }}: {{ item }}
                  </li>
                </ul>
                <span v-else>{{ alertDetail.text + ': ' + alertDetail.errors }}</span>
              </div>
              <div
                v-else
                class="alert-body"
              >
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <!-- search input -->
          <form @submit.prevent="submitForm">
            <b-row class="justify-content-end mb-1">
              <b-col cols="12">
                <b-form-group
                  v-b-tooltip.hover.top="'Upload Dokumen Matrix Efisiensi (dalam format .xls)'"
                  label="Dokumen Matrix Efisiensi"
                  label-for="letter_file"
                >
                  <b-form-file
                    ref="letter_file"
                    v-model="file1"
                    name="letter_file"
                    class="mt-1"
                    required
                    accept=".xls,.xlsx"
                    @input="checkFile"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-button
                  type="submit"
                  variant="primary"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </form>

        </b-card>

        <b-card
          header="Dokumen Efisiensi Anggaran Yang Pernah Diunggah"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <b-row
            v-for="(row, index) in rows"
            :key="index"
            class="mb-2"
          >
            <b-col cols="4">
              {{ row.created_on | formatDate }}
            </b-col>
            <b-col cols="6">
              {{ row.document_uri }}
            </b-col>
            <b-col cols="2">
              <b-link
                :href="getDoc(row.document_uri)"
                class="btn btn-sm btn-info mr-25 mb-25"
                target="_blank"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="12"
                />
              </b-link>
            </b-col>
          </b-row>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-embed
          :source="fileSurat"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

  </section>

</template>

<script>
import {
  BRow, BButton, BModal, BCol, BCard, BFormGroup, BFormFile, BAlert, BLink, VBTooltip,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import ProfileAbout from './module/PengajuanAbout.vue'
import ProfileSideMenu from './module/PengajuanSideMenu.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BAlert,
    BLink,
    VuePdfEmbed,
    ProfileAbout,
    ProfileSideMenu,
  },
  setup() {
    const yearBudget = Number(localStorage.getItem('tahunAnggaran'))

    return {
      yearBudget,
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role === '10') {
      next('/')
    } else {
      next()
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      fileSurat: '',
      userData: getUserData(),
      isLoading: false,
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        sort_by: [
          {
            column: 'letter_number',
            asc_desc: 'asc',
          },
        ],
        start: 0,
        length: 10,
      },
      rows: [],
      file1: null,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    checkFile(input) {
      if (input.size > 100 * 1024 * 1024) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Ukuran Dokumen Terlalu Besar`,
        }
      } else {
        this.alert = false
      }
    },
    submitForm() {
      document.getElementById('loading-bg').style.display = 'block'
      const formFile = new FormData()
      formFile.append('tahun_anggaran', localStorage.getItem('tahunAnggaran'))
      formFile.append('document', this.file1)

      this.$http.post(`/efisiensi-anggaran/add?token=${localStorage.getItem('userToken')}`, formFile, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'success',
            title: 'Success',
            text: 'File berhasil diunggah',
          }
          this.loadItems()
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: error.response.data.error,
          }
        })
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    loadItems() {
      this.rows = []
      this.$http.get(`/efisiensi-anggaran/my-data?token=${localStorage.getItem('userToken')}&tahun_anggaran=${localStorage.getItem('tahunAnggaran')}`)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
